import React, { Suspense } from 'react';
import Route from './Route';
import LoadingCircle from '../components/LoadingCircle/LoadingCircle';
import { prefixAr } from '../location/urls';

const PrivacyPolicy = React.lazy(() =>
  import('../pages/PrivacyPolicyPage/PrivacyPolicyPage')
);
const PrivacyPolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <PrivacyPolicy />
    </Suspense>
  );
};

const CookiePolicy = React.lazy(() =>
  import('../pages/CookiePolicyPage/CookiePolicyPage')
);
const CookiePolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <CookiePolicy />
    </Suspense>
  );
};

const ARCookiePolicy = React.lazy(() =>
  import('../pages/CookiePolicyPage/ARCookiePolicyPage')
);
const ARCookiePolicyPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <ARCookiePolicy />
    </Suspense>
  );
};

const TermsAndConditions = React.lazy(() =>
 import('../pages/TermsAndConditions/TermsAndConditions')
);
const TermsAndConditionsPage = () =>{
  return(
    <Suspense fallback={<LoadingCircle/>}>
      <TermsAndConditions />
    </Suspense>
  )
}
const TerminosLegales = React.lazy(() =>
  import('../pages/TerminosLegalesPage/TerminosLegalesPage')
);
const TerminosLegalesPage = () => {
  return (
    <Suspense fallback={<LoadingCircle />}>
      <TerminosLegales />
    </Suspense>
  );
};

const AsistenciaPage = React.lazy(() =>
  import('../pages/Asistencia/Asistencia')
);
const Asistencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AsistenciaPage />
  </Suspense>
);

const ContactPage = React.lazy(() => import('../pages/Contact/Contact'));
const Contact = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ContactPage />
  </Suspense>
);

const FaqPage = React.lazy(() => import('../pages/Faq/Faq'));
const Faq = () => (
  <Suspense fallback={<LoadingCircle />}>
    <FaqPage />
  </Suspense>
);

const Legales_PromocionesPage = React.lazy(() => import('../pages/LegalesPromociones/Legales_Promociones'));
const Legales_Promociones = () => (
  <Suspense fallback={<LoadingCircle />}>
    <Legales_PromocionesPage />
  </Suspense>
);
const PlanesPage = React.lazy(() => import('../pages/Planes/Planes'));
const Planes = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PlanesPage />
  </Suspense>
);

const PlanesDetailPage = React.lazy(() =>
  import('../pages/Planes/PlanesDetail/PlanesDetail')
);
const PlanesDetail = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PlanesDetailPage />
  </Suspense>
);

const NosotrosPage = React.lazy(() => import('../pages/Nosotros/Nosotros'));
const Nosotros = () => (
  <Suspense fallback={<LoadingCircle />}>
    <NosotrosPage />
  </Suspense>
);

const AboutAxaPage = React.lazy(() => import('../pages/AboutAxa/AboutAxa'));
const AboutAxa = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AboutAxaPage />
  </Suspense>
);

const TermsAndConditionsPDFPage = React.lazy(() =>
  import('../pages/TermsAndConditionsPDF/TermsAndConditionsPDF')
);
const TermsAndConditionsPDF = () => (
  <Suspense fallback={<LoadingCircle />}>
    <TermsAndConditionsPDFPage />
  </Suspense>
);

const PromocionesPage = React.lazy(() =>
  import('../pages/Promociones/Promociones')
);
const Promociones = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PromocionesPage />
  </Suspense>
);

const AMEXBeneficiosPage = React.lazy(() =>
  import('../pages/AMEXBeneficios/AMEXBeneficios')
);
const AMEXBeneficios = () => (
  <Suspense fallback={<LoadingCircle />}>
    <AMEXBeneficiosPage />
  </Suspense>
);

const CasoEmergenciaPage = React.lazy(() =>
  import('../pages/CasoEmergencia/CasoEmergencia')
);
const CasoEmergencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <CasoEmergenciaPage />
  </Suspense>
);

const NotificarRegistroPage = React.lazy(() =>
  import('../pages/NotificarRegistro/NotificarRegistro')
);
const NotificarRegistro = () => (
  <Suspense fallback={<LoadingCircle />}>
    <NotificarRegistroPage />
  </Suspense>
);

const StaticPageContentPage = React.lazy(() =>
  import('../pages/StaticPageContent/StaticPageContent')
);
const StaticPageContent = ({ ...props }) => (
  <Suspense fallback={<LoadingCircle />}>
    <StaticPageContentPage {...props} />
  </Suspense>
);

const FileClaimPage = React.lazy(() => import('../pages/FileClaim/FileClaim'));
const FileClaim = () => (
  <Suspense fallback={<LoadingCircle />}>
    <FileClaimPage />
  </Suspense>
);

const ContactanosPage = React.lazy(() =>
  import('../pages/Contactanos/Contactanos')
);
const Contactanos = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ContactanosPage />
  </Suspense>
);

const ComparePlansPage = React.lazy(() =>
  import('../pages/ComparePlans/ComparePlans')
);
const ComparePlans = () => (
  <Suspense fallback={<LoadingCircle />}>
    <ComparePlansPage />
  </Suspense>
);

const BenefitsPage = React.lazy(() => import('../pages/Benefits/Benefits'));
const Benefits = () => (
  <Suspense fallback={<LoadingCircle />}>
    <BenefitsPage />
  </Suspense>
);

const PhonePage = React.lazy(() => import('../pages/Phone/Phone'));
const Phone = () => (
  <Suspense fallback={<LoadingCircle />}>
    <PhonePage />
  </Suspense>
);

const POMT = React.lazy(() =>
  import('../pages/PeaceOfMindTerms/PeaceOfMindTerms')
);
const PeaceOfMindTerms = () => (
  <Suspense fallback={<LoadingCircle />}>
    <POMT />
  </Suspense>
);


const CotizarAsist = React.lazy(() =>
  import('../pages/CotizarAsistencia/CotizarAsistencia')
);
const CotizarAsistencia = () => (
  <Suspense fallback={<LoadingCircle />}>
    <CotizarAsist />
  </Suspense>
);

export const EspecificRoutes = {
  [prefixAr]: [
    <Route
      key={11}
      path="/contacta"
      component={() => <StaticPageContent type="contactanos" />}
    />,
    <Route
      key={12}
      path="/cookies"
      component={() => <StaticPageContent type="cookies" />}
    />,
    <Route key={13} path="/en-caso-de-emergencia" component={CasoEmergencia} />,
    <Route key={14} path="/faq" component={Faq} />,
    <Route key={15} path="/cotizar-asistencia" component={CotizarAsistencia} />,
    <Route
      key={505}
      path="/cotizar-asistencia/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={507}
      path="/cotizar-asistencia/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={508}
      path="/cotizar-asistencia/gracias"
      component={CotizarAsistencia}
    />,
    <Route
      key={16}
      path="/notificar-un-reintegro"
      component={NotificarRegistro}
    />,
    <Route
      key={17}
      path="/privacidad"
      component={() => <StaticPageContent type="policy" />}
    />,
    <Route key={17} path="/beneficios" component={() => <Benefits />} />,
    <Route
      key="arrepentimiento"
      path="/arrepentimiento"
      component={() => <StaticPageContent type="arrepentimiento" />}
    />,
    <Route
      key={941}
      path="/terms-conditions-pdf/:name"
      component={TermsAndConditionsPDF}
    />,
    <Route
      key={444}
      path="/politica-de-cookies"
      component={ARCookiePolicyPage}
    />,
    <Route key={666} path="/terminos-legales" component={TerminosLegalesPage} />,
    <Route key={667} path="/legales_promociones" component={Legales_Promociones} />
  ],
};